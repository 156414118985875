var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headTitle'),_c('img',{staticClass:"mobile-example-headImg",attrs:{"src":require("../../assets/image/mobile/mobile-product-case-banner-technology.png")}}),_vm._m(0),(_vm.hasDescription)?_c('div',{staticClass:"description"},[_c('div',{staticClass:"title1"},[_vm._v("项目介绍")]),(!_vm.showDescription)?_c('div',{staticClass:"title2",on:{"click":function($event){(_vm.showDescription = !_vm.showDescription)}}},[_vm._v("展开"),_c('i',{staticClass:"el-icon-arrow-down"})]):_vm._e(),(_vm.showDescription)?_c('div',{staticClass:"title2",on:{"click":function($event){(_vm.showDescription = !_vm.showDescription)}}},[_vm._v("收起"),_c('i',{staticClass:"el-icon-arrow-up"})]):_vm._e()]):_vm._e(),(_vm.hasDescription)?_c('div',{staticStyle:{"margin-top":"2rem","padding-bottom":"5.7rem"}},[_c('el-collapse-transition',[(_vm.showDescription)?_c('div',[_c('div',{staticClass:"description-box"})]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"mobile-head-catalogue"},[_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==1},on:{"click":function($event){(_vm.checkIndex=1)}}},[_c('span',[_vm._v("业务痛点")])]),_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==2},on:{"click":function($event){(_vm.checkIndex=2)}}},[_c('span',[_vm._v("实施方案")])]),_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==3},on:{"click":function($event){(_vm.checkIndex=3)}}},[_c('span',[_vm._v("实施收益")])])]),((_vm.checkIndex==1))?_c('div',{staticClass:"mobile-content-box1"},[_vm._m(1),_vm._m(2),_vm._m(3)]):((_vm.checkIndex==2))?_c('div',{staticClass:"mobile-content-box1"},[_vm._m(4)]):((_vm.checkIndex==3))?_c('div',{staticClass:"mobile-content-box1",staticStyle:{"margin-bottom":"5rem"}},[_vm._m(5)]):_vm._e(),_c('mobileFoot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-head-explain"},[_c('div',{staticClass:"mobile-head-explain-title"},[_vm._v("IT 服务门户")]),_c('div',{staticClass:"mobile-head-explain-content"},[_vm._v("某知名传媒科技公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 多业务场景 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 既需要现场运维录单，又有研发管理需求，多个业务系统之间需要相互隔离。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 缺乏统一视角 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 研发团队专注于技术实现，运维团队负责系统稳定性，而项目管理团队则关注进度和资源分配，三者分而治之，没有统一的管理看板导致各团队的效率常常受到其他团队的掣肘。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 信息孤岛 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 常常遇到孤岛式的研发、运维和项目管理，导致团队间信息不畅，进而影响整个研发流程的效率和成果质量。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-technology-1.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("私有化部署")]),_c('div',{staticClass:"title2"},[_vm._v("多应用私有化部署，其业务相互完全隔离。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-technology-2.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("应用内协作")]),_c('div',{staticClass:"title2"},[_vm._v("打点对话、在线文档和内部沟通等多种功能，将该企业研发、运维和项目团队紧密地联系在一起，提升了该企业内部团队的协作效率。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-technology-3.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("看板仪表盘视角")]),_c('div',{staticClass:"title2"},[_vm._v("项目经理实时掌握团队的工作动态，及时调整资源分配和进度计划，确保项目的顺利进行。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-technology-4.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("自动生成纪要及任务")]),_c('div',{staticClass:"title2"},[_vm._v("通过自动生成会议纪要及任务，大幅降低录入误差。会议中确定的工作内容可以自动转为任务，确保会议决策能及时落地。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"10rem"}},[_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("提升了研发、项目和运维团队协作效率。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("实现了跨团队、多项目的并行运作，并确保了所有项目均按期交付。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("全面记录会议内容，对待办事项完整跟踪，最终高效转化为工作成果。")])])
}]

export { render, staticRenderFns }