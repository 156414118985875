<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/mobile-product-case-banner-technology.png"/>
        <div class="mobile-head-explain">
          <div class="mobile-head-explain-title">IT 服务门户</div>
          <div class="mobile-head-explain-content">某知名传媒科技公司</div>
        </div>
        <div class="description" v-if="hasDescription">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;padding-bottom: 5.7rem;" v-if="hasDescription">
          <el-collapse-transition>
            <div v-if="showDescription">
              <div class="description-box"></div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue">
          <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
          <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
          <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
          <div class="ware-box1">
            <div class="title1">
              多业务场景
            </div>
            <div class="ware-box1-content">
              既需要现场运维录单，又有研发管理需求，多个业务系统之间需要相互隔离。
            </div>
          </div>
          <div class="ware-box1">
            <div class="title1">
              缺乏统一视角
            </div>
            <div class="ware-box1-content">
              研发团队专注于技术实现，运维团队负责系统稳定性，而项目管理团队则关注进度和资源分配，三者分而治之，没有统一的管理看板导致各团队的效率常常受到其他团队的掣肘。
            </div>
          </div>
          <div class="ware-box1">
            <div class="title1">
              信息孤岛
            </div>
            <div class="ware-box1-content">
              常常遇到孤岛式的研发、运维和项目管理，导致团队间信息不畅，进而影响整个研发流程的效率和成果质量。
            </div>
          </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
          <div class="purchase-box">
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-technology-1.svg"/>
              <div>
                <div class="title1">私有化部署</div>
                <div class="title2">多应用私有化部署，其业务相互完全隔离。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-technology-2.svg"/>
              <div>
                <div class="title1">应用内协作</div>
                <div class="title2">打点对话、在线文档和内部沟通等多种功能，将该企业研发、运维和项目团队紧密地联系在一起，提升了该企业内部团队的协作效率。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-technology-3.svg"/>
              <div>
                <div class="title1">看板仪表盘视角</div>
                <div class="title2">项目经理实时掌握团队的工作动态，及时调整资源分配和进度计划，确保项目的顺利进行。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-technology-4.svg"/>
              <div>
                <div class="title1">自动生成纪要及任务</div>
                <div class="title2">通过自动生成会议纪要及任务，大幅降低录入误差。会议中确定的工作内容可以自动转为任务，确保会议决策能及时落地。</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1" style="margin-bottom:5rem">
          <div style="margin-bottom:10rem">
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>提升了研发、项目和运维团队协作效率。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>实现了跨团队、多项目的并行运作，并确保了所有项目均按期交付。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>全面记录会议内容，对待办事项完整跟踪，最终高效转化为工作成果。</div>
          </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileProdutCaseTechnology',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            hasDescription: false,
        };
    },

    mounted() {
    },

    methods: {
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
    padding-bottom: 3rem;
    .title2{
        // position: absolute;
        // width: 4rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #005395;
        line-height: 2.8rem;
        margin: 1.9rem 0 0 3rem;
        cursor: pointer;
        .line{
            width: 6rem;
            height: 2rem;
            border-bottom: 3px solid #005395;
            border-radius: 50%;
            margin: -1rem 0 0 0rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    min-height: 10rem;
    padding: 3rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    text-align: justify;
    .title1{
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem;
    }
    .ware-box1-content {
      margin-top: 1.2rem;
      font-size: 2.2rem;
      font-family: 'CN_Regular';
      font-weight: 400;
      color: #999999;
      line-height: 3rem;
    }
}
.ware-box2{
    width: 66.8rem;
    height: 20rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem;
        margin: auto;
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;

    .card{
        width: 52.8rem;
        min-height: 30rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 2.5rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
    }
}
.purchase-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;

    margin-top: 2rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;

    margin: 1rem 0 0 3.5rem;
}
</style>
